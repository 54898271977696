export enum Statuses {
  Sending = 'sending',
  Success = 'success',
  Error = 'error',
}

export function getButtonProps(status: Statuses | null, text?: string): { classes: string; icon: string; text: string } {
  if (status === Statuses.Success) {
    return {
      classes: 'cursor-not-allowed pointer-events-none bg-green-500',
      icon: 'checkmark',
      text: 'Success!',
    }
  }

  if (status === Statuses.Sending) {
    return {
      classes: 'animate-pulse bg-charcoal cursor-not-allowed pointer-events-none opacity-25',
      icon: 'more',
      text: 'Subscribe',
    }
  }

  if (status === Statuses.Error) {
    return {
      classes: 'cursor-not-allowed pointer-events-none bg-red-600',
      icon: 'view-close',
      text: 'Error',
    }
  }

  return {
    classes: 'bg-charcoal',
    icon: 'enter',
    text: text ?? 'Subscribe',
  }
}
