import React from 'react';
import { Link } from 'gatsby';
import { theme } from '@/config/tailwind-theme';
import { styledComponent } from '@/model/styled-component';
import SubscribeForm from './subscribe-form';
import Logo from './logo';

function Footer({ className, style }: typeof styledComponent) {
  return (
    <footer
      className={`${className} bg-clay px-12 py-8 text-wheat relative font-title flex flex-wrap justify-between`}
      style={style}>
      <div className="min-w-1/3 py-2">
        <ul className="mb-8">
          <li className="mb-4"><Link to="/products">Shop</Link></li>
          <li className="mb-4"><Link to="/posts">Farm Journal</Link></li>
          <li className="mb-4"><Link to="/events">Classes + Events</Link></li>
          <li className="mb-4"><a href="https://instagram.com/pricklybearfarmstead" target="_blank">Follow Us</a></li>
          <li className="mb-4"><Link to="/contact">Contact Us</Link></li>
        </ul>

        <div className="text-sm">© Prickly Bear Farmstead {new Date().getFullYear()}</div>
      </div>

      <div className="min-w-1/3 py-2 flex justify-center items-center">
        <SubscribeForm />
      </div>

      <div className="min-w-1/3 py-2 flex justify-center items-center">
        <Logo className="opacity-25" color={theme.colors.slate} style={{ width: '12rem' }} />
      </div>

      <svg className="absolute left-0 fill-clay" style={{ bottom: '99%', transform: 'rotate(180deg)' }} viewBox="0 0 1373 121" ><path d="M 0 0 C 260 0 260 37 520 37 L 520 37 L 520 0 L 0 0 Z"></path> <path d="M 519 37 C 946 37 946 0 1373 0 L 1373 0 L 1373 0 L 519 0 Z"></path> </svg>
    </footer>
  )
}

export default Footer;
