const defaultTheme = require('tailwindcss/defaultTheme')

const bodyFont = ['Garbata', 'Cambria', 'serif'];

// tailwind.config.js
module.exports = {
  future: {},
  purge: ["./src/**/*.js", "./src/**/*.jsx", "./src/**/*.ts", "./src/**/*.tsx"],
  theme: {
    colors: {
      ...defaultTheme.colors,
      clay: '#B07053',
      charcoal: 'hsl(252, 5%, 21%)',
      slate: '#484948',
      wheat: '#EADCC3',
      offwhite: 'hsl(40, 60%, 96%)',
    },
    fontFamily: {
      title: ['Costa', 'Open Sans', 'sans-serif'],
      body: bodyFont,
    },
    letterSpacing: {
      ...defaultTheme.letterSpacing,
      widest: '.5em',
      wider: '.25em',
    },
    fill: theme => theme('colors'),
    extend: {
      typography: {
        DEFAULT: {
          css: {
            h1: { fontFamily: 'inherit' },
            h2: { fontFamily: 'inherit' },
            h3: { fontFamily: 'inherit' },
            h4: { fontFamily: 'inherit' },
            h5: { fontFamily: 'inherit' },
            p: { fontSize: '1.1rem' },
          },
        },
      },
    },
  },
  variants: {},
  plugins: [
    require('@tailwindcss/typography'),
  ],
  corePlugins: {
    container: false,
  },
}
