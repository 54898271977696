/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';

import Header from '@/components/header';
import Footer from '@/components/footer';

function Layout({ children, home = false }: { children: React.ReactNode, home?: boolean }) {
  return (
    <>
      <Header className={`${home ? 'bg-clay' : ''} w-full`} />

      <main className={`w-full min-h-screen relative z-30 ${home ? 'speckled' : ''}`}>{children}</main>

      <Footer className={`${home ? 'z-50' : 'z-20'}`}  />
    </>
  )
}

export default Layout;
