import MailchimpSubscribe from 'react-mailchimp-subscribe';
import React, { ReactElement } from 'react';
import Icon from 'supercons';
import { Formik, Field, Form } from 'formik';
import { getButtonProps, Statuses } from '@/model/statuses';

interface Values {
  EMAIL: string;
}

export interface Props {
  autofocus?: boolean;
  error?: string;
  form?: string;
  input?: string;
  button?: string;
  title?: string;
};

function CustomForm(
  { classes, status, message, subscribe }:
  { classes: Props; status: string | null, message: string, subscribe: Function }
) {
  return (
    <Formik
      initialValues={{ EMAIL: '' }}
      onSubmit={(values: Values) => { subscribe(values) }}>
      <Form
        className={classes.form ?? 'font-body flex flex-col relative items-center bg-wheat rounded-sm p-8'}>
        <div className={classes.title ?? 'block w-full font-title mb-6 text-charcoal'}>
            Get Updated on Events and Classes
        </div>

        <Field
          className={classes.input ?? 'px-2 py-2 pr-8 w-full rounded-sm text-charcoal bg-offwhite relative flex-grow-1'}
          // autoFocus={autofpcus}
          id="EMAIL"
          name="EMAIL"
          placeholder="Enter Your Email"
          // type="email"
          required={true} />

        <button
          className={`
            ${classes.button ?? 'transition-all rounded-sm pl-12 pr-4 h-12 font-title py-4 mt-4 flex justify-between items-center'}
            ${getButtonProps(status as Statuses).classes}
          `}
          type="submit">
          <span className="pr-8">{getButtonProps(status as Statuses).text}</span>
          <Icon
            glyph={getButtonProps(status as Statuses).icon}
            size={32} />
        </button>

        {status === 'error' && (
          <div className={classes.error ?? 'text-sm bg-charcoal font-normal px-4 py-2 absolute -bottom-4 rounded-sm'}>
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        )}
      </Form>
    </Formik>
  );
};

function SubscribeForm(props: Props = {}): ReactElement {
  return (
    <MailchimpSubscribe
      url={process.env.GATSBY_MAILCHIMP_URL ?? ''}
      render={({ subscribe, status, message }) => (
        <CustomForm
          classes={props}
          status={status}
          message={message as string}
          subscribe={subscribe} />
      )} />
  );
}

export default SubscribeForm;
