import { Link } from 'gatsby';
import { styledComponent } from '@/model/styled-component';
import React, { useContext } from 'react';
import { StateContext } from  '@/providers/state';
import Logo from '@/components/logo';
import SubscribeForm from '@/components/subscribe-form';
import Icon from 'supercons';

function Header({ className, style }: typeof styledComponent) {
  const { highlightSubscribe, toggleHighlightSubscribe, menuExpanded, toggleMenuExpanded } = useContext(StateContext) ?? {};

  function close() {
    toggleMenuExpanded(false);
    toggleHighlightSubscribe(false);
  }

  return (
    <nav className={`${className} flex flex-wrap items-center justify-between p-6`} style={style}>
      <div className="flex items-center flex-shrink-0 mr-6">
        <Link onClick={close} to="/"><Logo style={{ width: '4rem' }} /></Link>
      </div>

      <div className="flex">
        <button
          onClick={() => toggleMenuExpanded(!menuExpanded)}
          className="text-xs font-title tracking-wide flex items-center px-3 py-2 rounded mr-4">
          <div className="bg-slate rounded mr-2" style={{ width: '7vw', height: '0.25rem' }}></div>
          MENU
        </button>

        <div className="flex items-end">
          <Icon
            className="cursor-pointer snipcart-checkout"
            style={{
              stroke: 'currentColor',
              strokeWidth: '0.5px',
              position: 'relative',
              top: '-3px',
            }}
            glyph="briefcase"
            size={32} />
            <span className="font-title text-sm snipcart-items-count"></span>
          </div>
      </div>

      <div
        className={`${
          menuExpanded ? `block` : `hidden`
        } w-screen h-screen overflow-scroll bg-charcoal fixed top-0 left-0 z-50 block flex-grow text-wheat`}>
        <Icon
          onClick={close}
          className="text-white opacity-75 absolute right-0 top-0 m-2 lg:m-4 cursor-pointer z-40"
          glyph="view-close"
          size={64} />

        <div className="font-title text-2xl lg:text-4xl leading-loose lg:flex-grow p-4 pt-16 lg:p-8 lg:pt-8">
          <SubscribeForm
            button="transition-all rounded-sm pl-12 pr-4 h-12 font-title py-2 flex justify-between items-center border-2 border-wheat mb-4 hover:border-clay hover:text-clay"
            form={`w-full text-lg font-body flex flex-wrap items-center mb-4`}
            title={`text-lg font-title mb-4 w-full ${highlightSubscribe ? 'animate-bounce md:text-2xl text-wheat' : 'md:text-xl text-clay'}`}
            error="text-sm font-normal mt-4"
            input={`px-4 py-1 h-12 w-96 bg-offwhite rounded-sm mb-4 mr-2 text-charcoal`} />

          <div className="block mb-8">
            <div className="text-lg md:text-xl text-clay mb-2">(Get some merch)</div>
            <Link onClick={close} to="/products" className="link-underline border-wheat">Shop</Link>
          </div>

          <div className="block mb-8">
            <div className="text-lg md:text-xl text-clay mb-2">(See Stuff we’re working on)</div>
            <Link onClick={close} to="/posts" className="link-underline border-wheat">Farm Journal</Link>
          </div>

          <div className="block mb-8">
            <div className="text-lg md:text-xl text-clay mb-2">(Learn homesteading stuff)</div>
            <Link onClick={close} to="/events" className="link-underline border-wheat">Classes + Events</Link>
          </div>

          {/* <div className="block mb-8">
            <div className="text-lg md:text-xl text-clay mb-2">(We have a weather station and It has cool data)</div>
            <Link onClick={close} to="/" className="link-underline border-wheat">The Weather</Link>
          </div> */}

          <div className="block mb-8">
            <div className="text-lg md:text-xl text-clay mb-2">(We’re Bad at social Media but we try)</div>
            <a
              href="https://instagram.com/pricklybearfarmstead"
              target="_blank"
              className="link-underline border-wheat inline-flex items-center">
              <span>Follow Us</span>
              <Icon
                className="mx-2"
                glyph="enter"
                size={32} />
              <span className="text-sm">@pricklybearfarmstead</span>
            </a>
          </div>

          <div className="block mb-8">
            <div className="text-lg md:text-xl text-clay mb-2">(Questions, comments, etc...)</div>
            <Link onClick={close} to="/contact" className="link-underline border-wheat">Contact Us</Link>
          </div>

          {/* <Link
            to={`/`}
            className="block mb-8">
            <div className="text-xl text-clay">(We’ll do good things with it, We promise)</div>
            <div>Donate</div>
          </Link> */}
        </div>
      </div>
    </nav>
  )
}

Header.defaultProps = styledComponent;

export default Header;
